import React, { useEffect, useState } from 'react';
import { client } from "../services/api";

const Menu = ({ aoiWKT, setAois, onProductSelection }) => {
  const [sensors, setSensors] = useState();
  const [start, setStart] = useState();
  const [stop, setStop] = useState();
  const [products, setProducts] = useState([]);
  const [satellites, setSatellites] = useState([]);

  const changeHandler = () => {
    setSensors(document.getElementsByName('sensors')[0].value);
    try { setStart(new Date(document.getElementsByName('start')[0].value).toISOString().replace('.000', '')); } catch (e) { }
    try { setStop(new Date(document.getElementsByName('stop')[0].value).toISOString().replace('.000', '')); } catch (e) { }
  };

  const searchHandler = async () => {
    const res = await client.search(sensors, start, stop, aoiWKT);
    if (!res.ok) {
      alert(res.data.errorMessage);
      return;
    }
    let instrumentPrice = null;
    if (res.data.coveragePrices.length > 0) {
      instrumentPrice = res.data.coveragePrices[0].price.value / 1000;
    }
    setProducts(res.data.products.map(p => {
      const price = p.area / 1000000 * instrumentPrice;
      return { ...p, price}
    }));
  };

  useEffect(() => {
    client.satellites().then(res => {
      setSatellites(res.data.map((s) => s.name).sort());
    });
  }, []);

  useEffect(() => {
    setAois(products.map(p => p.coordinatesWKT));
  }, [products, setAois]);

  return (
    <div id="menu">
      sensors: <select name="sensors" onChange={changeHandler}>
        {satellites.map((s, idx) => <option key={idx}>{s}</option>)}
      </select><br />
      start: <input type="date" name="start" onChange={changeHandler} /><br />
      end: <input type="date" name="stop" onChange={changeHandler} /><br />
      <button onClick={searchHandler}>search</button>
      <ul>
        {products.map((p, idx) => <li key={idx} onClick={() => onProductSelection(p)}>{p.providerId}</li>)}
      </ul>
    </div>
  );
};

export default Menu;
import React, { useRef, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { stringify, parse } from 'wellknown';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Map = forwardRef(({ setAoiWKT, aois }, ref) => {
  const mapContainerRef = useRef(null);
  const [map, setMap] = useState();

  useImperativeHandle(ref, () => ({

    handleProductSelection(p) {
      const uri = p.overlayQl.replace('https://earthimages.geocento.com', process.env.REACT_APP_URL_API);
      map.addSource(`wms-${p.productId}`, {
        'type': 'raster',
        'tiles': [
          `${uri}&REQUEST=GetMap&SERVICE=WMS&VERSION=1.1.1&LAYERS=quicklook&width=512&height=512&srs=EPSG:3857&TRANSPARENT=TRUE&format=image/png&BBOX={bbox-epsg-3857}`
        ],
        'tileSize': 512
      });
      map.addLayer(
        {
          'id': `wms-${p.productId}`,
          'type': 'raster',
          'source': `wms-${p.productId}`,
          'paint': {}
        },
        'building'
      );
    }
  }));

  useEffect(() => {
    if (!map || !map.getSource('aois')) return;
    map.getSource('aois').setData({
      "type": "FeatureCollection",
      "features": aois.map(a => {
        return {
          "type": "Feature",
          "properties": {},
          "geometry": parse(a)
        }
      })
    });
  }, [map, aois]);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [12, 42],
      zoom: 5
    });
    setMap(map)

    var Draw = new MapboxDraw();

    map.addControl(Draw, 'top-left');

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.on('load', () => {
      map.addSource('aois', {
        type: 'geojson', data: {
          "type": "FeatureCollection",
          "features": []
        }
      });
      map.addLayer({
        'id': 'aois',
        'type': 'fill',
        'source': 'aois',
        'paint': {
          'fill-color': '#0080ff',
          'fill-opacity': 0.1,
          'fill-outline-color': 'red'
        }
      });
    });

    map.on('draw.create', function (e) {
      setAoiWKT(stringify(e.features[0]));
    });

    return () => map.remove();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='map-container' ref={mapContainerRef} />
  );
});

export default Map;
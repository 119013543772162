import React, { useState, useRef } from 'react';
import './scss/styles.scss';
import Map from './components/Map';
import Menu from './components/Menu';

function App() {
  const [aoiWKT, setAoiWKT] = useState();
  const [aois, setAois] = useState([]);
  const mapRef = useRef();
  const onProductSelection = (p) => {
    mapRef.current.handleProductSelection(p);
  }
  return (
    <>
      <Menu aoiWKT={aoiWKT} setAois={setAois} onProductSelection={onProductSelection} />
      <Map setAoiWKT={setAoiWKT} aois={aois} ref={mapRef} />
    </>
  );
}

export default App;
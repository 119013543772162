import apisauce from "apisauce";

export const create = (baseURL = process.env.REACT_APP_URL_API) => {
  const api = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "Authorization": `Token ${process.env.REACT_APP_GEOCENTO_TOKEN}`,
    },
    timeout: 60000,
  });

  const search = (sensors, start, stop, aoiWKT) => {
    return api.post(`search`, { sensors, start, stop, aoiWKT });
  };

  const satellites = () => {
    return api.get(`resources/satellites`, {});
  }

  const order = (productId, aoiWKT, selectionType, geometrySelectionWKT) => {
    return api.post(`orders`, {
      'catalogueProductOrderRequests': [{
        productId, aoiWKT, selectionType, geometrySelectionWKT
      }]
    })
  }

  return {
    api,
    search,
    satellites,
    order
  };
};

export const client = create();